<template>
  <h2 class="pr-3 oto-page-title">{{ title }}</h2>
</template>
<script>
export default {
  name: 'OtoPageTitle',
  props: {
    title: {
      type: String,
      default: 'Page Title',
    },
  },
}
</script>
<style scoped>
.oto-page-title {
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2e2e39;
}
</style>
